import React from 'react';

class ThemeSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = { theme: 'theme1' };
	}
	hover1(e) {
		this.setState({ theme: 'theme1' });
	}
	hover2(e) {
		this.setState({ theme: 'theme2' });
	}
	hover3(e) {
		this.setState({ theme: 'theme3' });
	}
	render() {
		return (
			<div className={`themesection ${this.state.theme}`}>
				<div className="themecontainer" id="theme1" onMouseEnter={this.hover1.bind(this)}>
					<div className="themebackground" />
					<div className="themetext">
						<h3>
							<span>{this.props.headers[0]}</span>
						</h3>
						<p>{this.props.texts[0]}</p>
					</div>
				</div>
				<div className="themecontainer" id="theme2" onMouseEnter={this.hover2.bind(this)}>
					<div className="themebackground" />
					<div className="themetext">
						<h3>
							<span>{this.props.headers[1]}</span>
						</h3>
						<p>{this.props.texts[1]}</p>
					</div>
				</div>
				<div className="themecontainer" id="theme3" onMouseEnter={this.hover3.bind(this)}>
					<div className="themebackground" />
					<div className="themetext">
						<h3>
							<span>{this.props.headers[2]}</span>
						</h3>
						<p>{this.props.texts[2]}</p>
					</div>
				</div>
			</div>
		);
	}
}

export default ThemeSection;
