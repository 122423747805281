import React from 'react';
import { Parallax, Background } from 'react-parallax';

class ImageSection extends React.Component {
	render() {
		return (
			<div className={`imagesection ${this.props.imageRight ? 'right' : ''}`}>
				<Parallax strength={150}>
					<Background>
						<div className="imagesectionimg">
							<img src={this.props.image} alt={this.props.header} />
						</div>
					</Background>
					<div className="imagesectiontext">
						<h3>{this.props.header}</h3>
						<h4>{this.props.location}</h4>
						<p>{this.props.text}</p>
					</div>
				</Parallax>
			</div>
		);
	}
}

export default ImageSection;
