import React from 'react';
import Layout from '../components/layout';
import Typed from 'react-typed';
import ImageSection from '../components/imagesection';
import ThemeSection from '../components/themesection';
import investment1 from '../images/1-transit-oriented-dev.jpg';
import investment2 from '../images/2-shared-prosperity.jpg';
import investment3 from '../images/3-purpose-built.jpg';
import investment4 from '../images/4-sust-hospitality.jpg';
import investment5 from '../images/5-sustainable-development.jpg';
import investment6 from '../images/6-renewable-energy.jpg';
import investment7 from '../images/7-heartland.jpg';
//import { Link } from 'gatsby';

const IndexPage = () => (
	<Layout>
		<div id="hero" className="mainsection">
			<h1>
				SURF
				<Typed
					strings={[
						'<br />Sustainable Real Estate Fund',
						'<br />Sustainable Urban Revitalization Finance',
						'<br />Sustainable Renewables &amp; Farming',
						'&nbsp;Impact Capital'
					]}
					typeSpeed={80}
					startDelay={0}
					backSpeed={20}
					backDelay={1500}
					smartBackspace={false}
				/>
			</h1>
		</div>
		<div id="intro" className="mainsection">
			<h2>Social and Sustainable Impact</h2>
			<p>
				SURF’s mission is to achieve opportunistic returns through investments that will produce positive social and
				sustainable outcomes. SURF has closed over $1 billion in investments, with projects in over 30 countries. SURF
				is both an acronym for Sustainable Urban Real Estate Fund and a metaphor for our persistent search for truly
				unique projects and partners.
			</p>
		</div>
		<div id="themes" className="mainsection">
			<h2>
				<br />
				Themes
			</h2>
			<ThemeSection
				headers={['Impact Real Estate Development', 'Sustainable Hospitality', 'Renewable Energy and Conservation']}
				texts={[
					`SURF backs talented professionals with profitable solutions to disinvested neighborhoods and solutions that protect communities from displacement. SURF’s projects and partners operate almost exclusively in federally qualified Opportunity Zones.`,
					`SURF invests in sustainable hotels and supports the activities of hospitality brands that embrace sustainability and environmental preservation as their core values.`,
					`SURF supports sustainability in the built environment by investing in technologies and platforms that leverage our relationships in the real estate industry, including commercial-scale rooftop solar and geothermal and urban farming technologies.`
				]}
			/>
		</div>
		<div id="investments" className="mainsection">
			<h2>
				<br />
				Our Investments
			</h2>
			<ImageSection
				header={'Community-led Development'}
				location={'USA'}
				text={`Through its partnership with Purpose Built Communities, SURF Asset Management LLC invests in housing, commercial, and educational and wellness assets to improve the community and eliminate sources of toxic stress that impede the healthy development of children. By leveraging our funding with public and philanthropic investments, we can more effectively advance our goal of creating healthy, vibrant communities where families of all incomes can prosper and thrive.`}
				image={investment3}
			/>
			<ImageSection
				header={'Transit-oriented Development'}
				location={'New York'}
				text={`SURF and SURF New Urban Partners are invested with Renaissance Downtowns, a masterplan developer that transforms neighborhoods into large-scale walkable, transit-oriented communities. Through sincere community engagement, they are able to secure projects for opportunistic returns.`}
				image={investment1}
				imageRight={true}
			/>
			<ImageSection
				header={'Shared Prosperity'}
				location={'California'}
				text={`SURF Asset Management LLC is partnered with Bay Area developer Common Ground, investing in large commercial and residential projects that protect low-income residents, community arts organizations and social service providers from the effects of gentrification and the soaring cost of living.`}
				image={investment2}
			/>
			<ImageSection
				header={'Sustainable Hospitality'}
				location={'Global'}
				text={`Since our founding, SURF Hotels has invested and advised on sustainable hotel projects and hospitality platforms located in over 32 countries. Our current partner is Six Senses, ranked top global brand by Travel & Leisure in 2017 and 2018. We also invest and advise on projects with hotel developers, conditioned on their commitments to sustainability. SURF acquired Aman Resorts in 2014 for its current owners.`}
				image={investment4}
				imageRight={true}
			/>
			<ImageSection
				header={'Sustainable Real Estate'}
				location={'Australia & India'}
				text={`Sustainable Pte Ltd has invested and advised private equity funds on land and development projects with completed values projected at over $700 million.`}
				image={investment5}
			/>
			<ImageSection
				header={'Renewable Energy'}
				location={'Hong Kong & China'}
				text={`Sustainable Pte Ltd invested in Asia Clean Capital, the largest provider of rooftop solar and geothermal energy solutions to multinational companies in China.`}
				image={investment6}
				imageRight={true}
			/>
			<ImageSection
				header={'Sustainable Agriculture'}
				location={'USA'}
				text={`SURF advises Heartland Farm Energy, a company commercializing advanced technology solutions to radically reduce energy and improve the health and well-being of animals.`}
				image={investment7}
			/>
		</div>
		<span id="contactus">&nbsp;</span>
	</Layout>
);

export default IndexPage;
